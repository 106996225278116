<template>
  <div v-if="info" class="pagination">
    <div class="pagination-info">
      {{
        info.totalItems === 0
          ? ""
          : pageCount * info.currentPage - pageCount + 1 + " - "
      }}
      {{
        pageCount * info.currentPage > info.totalItems
          ? info.totalItems
          : pageCount * info.currentPage
      }}
      из
      {{ info.totalItems }}
    </div>
    <div class="pagination-data">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="info.currentPage"
        :page-size="pageCount"
        prev-text="< Пред"
        next-text="След >"
        layout=" prev, pager, next"
        :total="info.totalItems"
      >
      </el-pagination>
    </div>
    <div class="pagination-count">
      <div class="pagination-count__text">
        <span class="hide-lg  hide-md  hide-sm">На странице</span>
        <span class="hide-xs">Строк на странице</span>
      </div>
      <el-select
        v-model="pageCount"
        popper-class="pagination-select"
        @change="handleSizeChange"
      >
        <el-option
          v-for="item in options"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    info: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: () => [10, 50, 100]
    },
    initPageCount: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      pageCount: this.initPageCount
    };
  },
  methods: {
    handleSizeChange() {
      this.$emit("changedPage", 1, this.pageCount);
    },
    handleCurrentChange(val) {
      this.$emit("changedPage", val, this.pageCount);
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: $form-def-label-color;
  margin-top: 1.6rem;

  &-info {
    max-width: 20rem;
  }

  &-select {
    width: 4.7rem;

    .el-select-dropdown__item {
      padding: 0 1.2rem;
      font-size: 1.4rem;
      color: $form-def-label-color;

      &.selected {
        color: $primary;
      }
    }
  }

  .el-pagination {
    .btn-prev,
    .btn-next {
      background-color: transparent;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $primary;
    }

    button:disabled {
      color: $form-def-label-color;
    }
  }

  .el-pager {
    li {
      background-color: transparent;
      font-size: 1.4rem;
      color: $primary;
      font-weight: 600;
      border-radius: 6px;
      min-width: 28px;

      &.active {
        background: linear-gradient(
          180deg,
          rgba(99, 188, 245, 0.15) -29.17%,
          rgba(17, 153, 240, 0.15) 100%
        );
      }
    }
  }

  &-count {
    display: flex;
    align-items: center;

    .el-select {
      margin-left: 1rem;
      position: relative;
      width: auto;

      &:after {
        content: "";
        position: absolute;
        top: 0.9rem;
        right: 0.7rem;
        background: url("../../assets/icons/icon-arrow-down.svg") center
          no-repeat;
        display: block;
        width: 1rem;
        height: 1rem;
      }

      .el-input--suffix .el-input__inner {
        padding: 0 1.2rem;
        width: 4.7rem;
        height: 28px;
        background: $basicWhite;
        border: 1px solid #e0e2ed;
        border-radius: 2px;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $primary;
      }

      .el-input__suffix {
        display: none;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .pagination {
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .pagination-data {
    width: 100%;
    margin-bottom: 24px;
    order: -10;

    .el-pagination {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before,
      &::after {
        display: none;
      }
    }

    .btn-next,
    .btn-prev {
      padding: 0;
    }
  }
  .pagination-count__text {
    color: #a1adbb;
  }
}
</style>
