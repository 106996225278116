<template>
  <div class="professions">
    <div class="professions__title">
      <PageTitle text="Специальности" />
      <el-button
        type="primary"
        @click.prevent="addProfession"
        class="professions__add"
      >
        <span class="hide-sm hide-xs">+ </span>Добавить
        <span class="hide-sm hide-xs">специальность</span>
      </el-button>
    </div>
    <div
      v-if="professionsList && professionsList.length"
      class="professions__table"
      v-loading="loading"
    >
      <el-table :data="professionsList" @header-click="sortData">
        <el-table-column
          :index="0"
          :label-class-name="
            typeSort === 'title'
              ? 'is-sortable sortable ' + sortOrder
              : 'is-sortable'
          "
        >
          <template slot="header">
            Специальность
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
              <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
            </svg>
          </template>
          <template slot-scope="scope">
            <div
              class="professions__table-item"
              :class="{ 'color-muted': !scope.row.name }"
            >
              {{ !!scope.row.name ? scope.row.name : "Нет названия" }}
            </div>
            <div
              class="professions__table-item professions__table-item--description hide-md"
              :class="{ 'color-muted': !scope.row.description }"
            >
              {{
                !!scope.row.description
                  ? scope.row.description
                  : "Нет информации"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="1">
          <template slot="header"> Требования </template>
          <template slot-scope="scope">
            <div
              class="professions__table-item hide-xs hide-sm"
              :class="{ 'color-muted': !scope.row.description }"
            >
              {{
                !!scope.row.description
                  ? scope.row.description
                  : "Нет информации"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="2">
          <template slot="header">
            <span class="hide-sm hide-xs">Вакансия открыта в проекте</span>
            <span class="hide-lg hide-md">Открыта в проекте</span>
          </template>
          <template slot-scope="scope">
            <div
              class="professions__table-item"
              :class="{
                'color-muted': !(
                  scope.row.projects && scope.row.projects.length
                )
              }"
            >
              <ul
                class="professions__table-list"
                v-if="scope.row.projects && scope.row.projects.length"
              >
                <li
                  class="professions__table-list-item"
                  v-for="(project, index) in scope.row.projects"
                  :key="index"
                >
                  {{ project.title }}
                </li>
              </ul>
              <span v-else>Открытых вакансий нет</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" :index="3">
          <template slot-scope="scope">
            <div class="professions__table-actions">
              <div class="more-options hide-lg hide-md">
                <span
                  @click="scope.row.visibleMoreOptions = true"
                  class="el-dropdown-link"
                >
                  <img src="@/assets/icons/icon-option.svg" />
                </span>
                <el-dialog
                  title="Действия"
                  :visible.sync="scope.row.visibleMoreOptions"
                  width="288px"
                >
                  <el-dropdown-item
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'more', id: scope.row.id });
                    "
                  >
                    <img src="@/assets/icons/icon-info.svg" alt="Информация" />
                    Подробнее
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'add-on', id: scope.row.id });
                    "
                  >
                    <img
                      src="@/assets/icons/icon-project-black.svg"
                      alt="Добавить в проект"
                    />
                    Добавить в проект
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'delete', id: scope.row.id });
                    "
                    class="removed"
                  >
                    <img
                      src="@/assets/icons/icon-removed-red.svg"
                      alt="Удалить"
                    />
                    Удалить
                  </el-dropdown-item>
                </el-dialog>
              </div>
              <el-dropdown
                trigger="click"
                @command="handleCommand"
                class="hide-sm hide-xs"
              >
                <span class="el-dropdown-link">
                  <img
                    src="@/assets/icons/icon-option.svg"
                    alt="Контекстное меню"
                  />
                </span>
                <el-dropdown-menu slot="dropdown" class="professions__options">
                  <el-dropdown-item
                    :command="{ name: 'more', id: scope.row.id }"
                  >
                    <img src="@/assets/icons/icon-info.svg" alt="Информация" />
                    Подробнее
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{ name: 'add-on', id: scope.row.id }"
                  >
                    <img
                      src="@/assets/icons/icon-project-black.svg"
                      alt="Добавить в проект"
                    />
                    Добавить в проект
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{ name: 'delete', id: scope.row.id }"
                    class="removed"
                  >
                    <img
                      src="@/assets/icons/icon-removed-red.svg"
                      alt="Удалить"
                    />
                    Удалить
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="professions__nav">
      <Pagination
        :info="pagination"
        :init-page-count="pageCount"
        @changedPage="changePage"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";
import Pagination from "../components/blocks/Pagination";

export default {
  name: "Professions",

  components: {
    PageTitle,
    Pagination
  },

  data() {
    return {
      loading: false,
      typeSort: "",
      ascOrder: true,
      page: 1,
      pageCount: 8
    };
  },

  computed: {
    professionsList() {
      return this.$store.state.Professions.professionsList?.map(item => ({
        ...item,
        visibleMoreOptions: false
      }));
    },
    pagination() {
      return this.$store.state.Professions.pagination;
    },
    successCreate() {
      return this.$store.state.Professions.successCreate;
    },
    successEdit() {
      return this.$store.state.Professions.successEdit;
    },
    successRemove() {
      return this.$store.state.Professions.successRemove;
    },
    sortOrder() {
      let order = "asc";
      if (!this.ascOrder) order = "desc";
      return order;
    },
    ready() {
      return this.$store.state.Auth.ready;
    },
    currentCompany() {
      return this.$store.state.Auth.currentCompanyOfUser;
    },
    routeQuery() {
      return this.$route.query;
    }
  },

  watch: {
    successCreate(val) {
      if (val) {
        this.page = 1;
        this.getProfessions();
        this.$store.commit("Professions/setData", {
          label: "successCreate",
          data: false
        });
      }
    },
    successEdit(val) {
      if (val) {
        this.getProfessions();
        this.$store.commit("Professions/setData", {
          label: "successEdit",
          data: false
        });
      }
    },
    successRemove(val) {
      if (val) {
        this.page = 1;
        this.getProfessions();
        this.$store.commit("Professions/setData", {
          label: "successRemove",
          data: false
        });
      }
    },
    ready(val) {
      if (val) {
        this.getProfessions();
      }
    },
    routeQuery: {
      deep: true,
      handler() {
        this.getProfessions();
      }
    }
  },

  created() {
    if (this.ready) {
      this.getProfessions();
    }
  },

  destroyed() {
    this.hideProfession();
  },

  methods: {
    getProfessions() {
      this.loading = true;
      this.$store
        .dispatch("Professions/getProfessions", {
          page: this.$route.query.page || this.page,
          perPage: this.pageCount,
          sortField: this.typeSort ? this.typeSort : null,
          sort: this.typeSort ? this.sortOrder : null,
          company_id: this.currentCompany
        })
        .then(() => {
          this.loading = false;
        });
    },
    changePage(page, count) {
      this.page = page;
      this.pageCount = count;
      this.$router.push({ query: { page } });
    },
    addProfession() {
      this.$store.commit("Option/setData", {
        label: "detailProfession",
        data: null
      });
      this.$store.commit("Option/setData", {
        label: "currentOption",
        data: "ProfessionCard"
      });
    },
    handleCommand(command) {
      switch (command.name) {
        case "delete":
          this.removeProfession(command.id);
          break;
        case "add-on":
          this.addOn(command.id);
          break;
        case "more":
          this.showProfession(command.id);
          break;
      }
    },
    showProfession(id) {
      this.loading = true;
      this.$store.dispatch("Professions/getProfession", id).then(({ data }) => {
        this.loading = false;
        this.$store.commit("Option/setData", {
          label: "detailProfession",
          data: { ...data.data }
        });
        this.$store.commit("Option/setData", {
          label: "currentOption",
          data: "ProfessionCard"
        });
      });
    },
    addOn(id) {
      this.$router.push({ path: "/add-to-project?for=" + id });
    },
    removeProfession(id) {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}" alt="Удаление специальности">
        <div>
          <div class="remove-message__title">
            Удаление специальности
          </div>
          <div class="remove-message__text">
            Вы действительно хотите удалить запись о специальности из базы данных и все ее связи?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Удалить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        this.$store
          .dispatch("Professions/deleteProfession", id)
          .then(() => {
            this.page = 1;
            this.getProfessions();
            this.$notify({
              title: "Успех",
              message: "Специальность удалена",
              type: "success"
            });
          })
          .catch(err => {
            console.error(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    },
    sortData(row) {
      switch (row.index) {
        case 0:
          if (this.typeSort === "title") {
            this.ascOrder = !this.ascOrder;
          } else {
            this.ascOrder = true;
          }
          this.typeSort = "title";
          break;
      }
      this.getProfessions();
    },
    hideProfession() {
      this.$store.commit("Option/closeOption");
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.professions {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
  }

  &__table {
    .el-table th {
      height: 5.8rem;
      padding: 0;
    }

    &-list {
      list-style: none;

      &-item {
        margin-bottom: 0.8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-item {
      &--description {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .professions__add {
    border-right: none;
    background: none;
    border-radius: 0;
    color: #1199f0;
    padding: 0;
    border-bottom: 1px solid #1199f0;
  }

  .professions {
    .professions__table {
      margin: 0 -16px;

      .cell {
        display: block;
      }
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }

    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        height: auto;

        &:nth-child(1),
        &:nth-child(3) {
          border-radius: 0;
          width: calc(50% - 12px);
          text-align: left;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }
        &:nth-child(3) {
          width: calc(50% + 12px);

          .cell {
            padding-left: 0;
          }
        }

        &:last-child,
        &:nth-child(2),
        &:nth-child(4) {
          display: none;
        }

        svg {
          display: none;
        }
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
      }
      td {
        position: static;
        border-radius: 0;
        padding: 0;
        border: none;
        text-align: left;
        font-size: 12px;
        line-height: 16px;

        &:nth-child(1) {
          width: calc(50% - 12px);
          order: -40;
          font-size: 14px;
          line-height: 20px;
          padding-right: 10px;
          font-weight: 600;
        }

        &:nth-child(3) {
          padding-right: 10px;
          width: calc(50% - 12px);
          order: -35;
        }

        &:nth-child(4) {
          width: 24px;
          order: -30;
        }

        &:nth-child(2) {
          margin-top: 8px;
          width: 100%;
          order: -25;
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }
    }
  }
  .professions__table-list-item {
    margin-bottom: 0;
  }
  .professions__table-actions {
    padding: 0;
  }
}
</style>
