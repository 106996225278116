var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.info ? _c('div', {
    staticClass: "pagination"
  }, [_c('div', {
    staticClass: "pagination-info"
  }, [_vm._v(" " + _vm._s(_vm.info.totalItems === 0 ? "" : _vm.pageCount * _vm.info.currentPage - _vm.pageCount + 1 + " - ") + " " + _vm._s(_vm.pageCount * _vm.info.currentPage > _vm.info.totalItems ? _vm.info.totalItems : _vm.pageCount * _vm.info.currentPage) + " из " + _vm._s(_vm.info.totalItems) + " ")]), _c('div', {
    staticClass: "pagination-data"
  }, [_c('el-pagination', {
    attrs: {
      "current-page": _vm.info.currentPage,
      "page-size": _vm.pageCount,
      "prev-text": "< Пред",
      "next-text": "След >",
      "layout": " prev, pager, next",
      "total": _vm.info.totalItems
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.info, "currentPage", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.info, "currentPage", $event);
      }
    }
  })], 1), _c('div', {
    staticClass: "pagination-count"
  }, [_vm._m(0), _c('el-select', {
    attrs: {
      "popper-class": "pagination-select"
    },
    on: {
      "change": _vm.handleSizeChange
    },
    model: {
      value: _vm.pageCount,
      callback: function callback($$v) {
        _vm.pageCount = $$v;
      },
      expression: "pageCount"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1)]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pagination-count__text"
  }, [_c('span', {
    staticClass: "hide-lg  hide-md  hide-sm"
  }, [_vm._v("На странице")]), _c('span', {
    staticClass: "hide-xs"
  }, [_vm._v("Строк на странице")])]);
}]

export { render, staticRenderFns }