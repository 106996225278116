var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "professions"
  }, [_c('div', {
    staticClass: "professions__title"
  }, [_c('PageTitle', {
    attrs: {
      "text": "Специальности"
    }
  }), _c('el-button', {
    staticClass: "professions__add",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addProfession($event);
      }
    }
  }, [_c('span', {
    staticClass: "hide-sm hide-xs"
  }, [_vm._v("+ ")]), _vm._v("Добавить "), _c('span', {
    staticClass: "hide-sm hide-xs"
  }, [_vm._v("специальность")])])], 1), _vm.professionsList && _vm.professionsList.length ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "professions__table"
  }, [_c('el-table', {
    attrs: {
      "data": _vm.professionsList
    },
    on: {
      "header-click": _vm.sortData
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0,
      "label-class-name": _vm.typeSort === 'title' ? 'is-sortable sortable ' + _vm.sortOrder : 'is-sortable'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item",
          class: {
            'color-muted': !scope.row.name
          }
        }, [_vm._v(" " + _vm._s(!!scope.row.name ? scope.row.name : "Нет названия") + " ")]), _c('div', {
          staticClass: "professions__table-item professions__table-item--description hide-md",
          class: {
            'color-muted': !scope.row.description
          }
        }, [_vm._v(" " + _vm._s(!!scope.row.description ? scope.row.description : "Нет информации") + " ")])];
      }
    }], null, false, 2420080291)
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Специальность "), _c('svg', {
    attrs: {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 5H0L3 9L6 5H3Z",
      "fill": "#A2A7BE"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 4L6 4L3 0L0 4L3 4Z",
      "fill": "#A2A7BE"
    }
  })])])], 2), _c('el-table-column', {
    attrs: {
      "index": 1
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item hide-xs hide-sm",
          class: {
            'color-muted': !scope.row.description
          }
        }, [_vm._v(" " + _vm._s(!!scope.row.description ? scope.row.description : "Нет информации") + " ")])];
      }
    }], null, false, 3075747797)
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Требования ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 2
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item",
          class: {
            'color-muted': !(scope.row.projects && scope.row.projects.length)
          }
        }, [scope.row.projects && scope.row.projects.length ? _c('ul', {
          staticClass: "professions__table-list"
        }, _vm._l(scope.row.projects, function (project, index) {
          return _c('li', {
            key: index,
            staticClass: "professions__table-list-item"
          }, [_vm._v(" " + _vm._s(project.title) + " ")]);
        }), 0) : _c('span', [_vm._v("Открытых вакансий нет")])])];
      }
    }], null, false, 1414449791)
  }, [_c('template', {
    slot: "header"
  }, [_c('span', {
    staticClass: "hide-sm hide-xs"
  }, [_vm._v("Вакансия открыта в проекте")]), _c('span', {
    staticClass: "hide-lg hide-md"
  }, [_vm._v("Открыта в проекте")])])], 2), _c('el-table-column', {
    attrs: {
      "width": "100",
      "index": 3
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-actions"
        }, [_c('div', {
          staticClass: "more-options hide-lg hide-md"
        }, [_c('span', {
          staticClass: "el-dropdown-link",
          on: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = true;
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg")
          }
        })]), _c('el-dialog', {
          attrs: {
            "title": "Действия",
            "visible": scope.row.visibleMoreOptions,
            "width": "288px"
          },
          on: {
            "update:visible": function updateVisible($event) {
              return _vm.$set(scope.row, "visibleMoreOptions", $event);
            }
          }
        }, [_c('el-dropdown-item', {
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'more',
                id: scope.row.id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg"),
            "alt": "Информация"
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'add-on',
                id: scope.row.id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-project-black.svg"),
            "alt": "Добавить в проект"
          }
        }), _vm._v(" Добавить в проект ")]), _c('el-dropdown-item', {
          staticClass: "removed",
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'delete',
                id: scope.row.id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg"),
            "alt": "Удалить"
          }
        }), _vm._v(" Удалить ")])], 1)], 1), _c('el-dropdown', {
          staticClass: "hide-sm hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg"),
            "alt": "Контекстное меню"
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "professions__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'more',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg"),
            "alt": "Информация"
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'add-on',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-project-black.svg"),
            "alt": "Добавить в проект"
          }
        }), _vm._v(" Добавить в проект ")]), _c('el-dropdown-item', {
          staticClass: "removed",
          attrs: {
            "command": {
              name: 'delete',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg"),
            "alt": "Удалить"
          }
        }), _vm._v(" Удалить ")])], 1)], 1)], 1)];
      }
    }], null, false, 161381985)
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "professions__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination,
      "init-page-count": _vm.pageCount
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }